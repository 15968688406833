import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useLogout } from "src/hooks/useLogout";
import FullPageContainer from "src/shared/FullPageContainer/FullPageContainer";
import SectionContainer from "src/shared/SectionContainer/SectionContainer";

export const Unauthorized = () => {
  const { t } = useTranslation("common");

  const logout = useLogout();

  return (
    <FullPageContainer>
      <SectionContainer sx={{ px: "1rem", py: "1.75rem" }}>
        <Typography component="h1" variant="h2">
          {t("unauthorized.title")}
        </Typography>
        <p>{t("unauthorized.description")}</p>
        <Button variant="contained" color="inherit" onClick={logout}>
          {t("logout")}
        </Button>
      </SectionContainer>
    </FullPageContainer>
  );
};
