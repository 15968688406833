import { api } from "src/services/store/apiSlice";
import { apiEndpoints } from "src/services/utils";
import TAGS from "src/services/utils/tags";
import { IInviteMembership, IMembership, IUser } from "src/types";

export interface ITwilioCredentials {
  username?: string;
  password: string;
}

const membershipApi = api.injectEndpoints({
  endpoints: (builder) => ({
    selectMembership: builder.mutation<IUser, string>({
      query: (id) => ({
        url: apiEndpoints.selectMembership(id),
        method: "POST",
        body: {},
      }),
      invalidatesTags: [
        TAGS.CONVERSATION,
        TAGS.USER,
        TAGS.INVITATION,
        TAGS.SIGNATURE_REQUEST,
      ],
    }),

    getAllMemberships: builder.query<IMembership[], void>({
      query: () => apiEndpoints.memberships(),
      providesTags: [TAGS.MEMBERSHIP],
    }),

    inviteMembership: builder.mutation<IMembership, IInviteMembership>({
      query: (data) => ({
        url: apiEndpoints.memberships(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAGS.MEMBERSHIP],
    }),

    updateMembership: builder.mutation<
      IMembership,
      { id: string; data: Partial<IMembership> }
    >({
      query: ({ id, data }) => ({
        url: apiEndpoints.membership(id),
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [TAGS.MEMBERSHIP],
    }),

    deleteMembership: builder.mutation<IMembership, string>({
      query: (id) => ({
        url: apiEndpoints.membership(id),
        method: "DELETE",
      }),
      invalidatesTags: [TAGS.MEMBERSHIP],
    }),

    twilioCredentials: builder.mutation<void, ITwilioCredentials>({
      query: (data) => ({
        url: apiEndpoints.twilioCredentials(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAGS.MEMBERSHIP],
    }),
  }),
});

export const {
  useSelectMembershipMutation,
  useGetAllMembershipsQuery,
  useLazyGetAllMembershipsQuery,
  useUpdateMembershipMutation,
  useDeleteMembershipMutation,
  useInviteMembershipMutation,
  useTwilioCredentialsMutation,
} = membershipApi;
