import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import FullPageContainer from "src/shared/FullPageContainer/FullPageContainer";
import SectionContainer from "src/shared/SectionContainer/SectionContainer";

export interface IErrorProps {
  status?: number;
}

const Error = ({ status }: IErrorProps) => {
  const { t } = useTranslation("common");
  return (
    <FullPageContainer>
      <SectionContainer sx={{ px: "1rem", py: "1.75rem" }}>
        <Typography component="h1" variant="h2">
          {t("error")} {status!}
        </Typography>
        {status === 404 ? (
          <>
            <p>{t("errorNotFound")}</p>
            <p>{t("errorNotFoundRedirectTnS")}</p>
            <p>{t("errorNotFoundRedirectHome")}</p>
          </>
        ) : (
          <p>{t("errorOccurred")}</p>
        )}
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          {t("backToHome")}
        </Button>
        {localStorage.getItem("access_token") &&
          localStorage.getItem("refresh_token") && (
            <Box>
              <Typography sx={{ pt: 3, pb: 1 }} component="p" variant="body1">
                {t("ifTheProblemPersists")}
              </Typography>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  localStorage.clear();
                  window.location.href = "/";
                }}
              >
                {t("logout")}
              </Button>
            </Box>
          )}
      </SectionContainer>
    </FullPageContainer>
  );
};

export default Error;
