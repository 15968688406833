import { useEffect, useState } from "react";
import { MsalProvider } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";
import { Provider as ReduxProvider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { publicClientApplication } from "src/services/api/initMSAL";
import MainRoutes from "src/routes";
import { InteractiveLoadingProvider } from "src/utils/withInteractiveLoading/interactiveLoading";
import useResizeObserver from "src/hooks/useResizeObserver";

import { store } from "./store";

const App = (): JSX.Element => {
  const [jiraIframe, setJiraIframe] = useState<HTMLElement | null>(null);
  useEffect(() => setJiraIframe(document.getElementById("jsd-widget")), []);
  useResizeObserver(jiraIframe, (el) => {
    if (el.style.width === "100%") {
      el.style.width =
        "calc(100% - env(safe-area-inset-right) - env(safe-area-inset-left))";
      el.style.height =
        "calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom))";
    }
    el.style.bottom = "env(safe-area-inset-bottom)";
    el.style.right = "env(safe-area-inset-right)";
  });
  return (
    <ReduxProvider store={store}>
      <MsalProvider instance={publicClientApplication}>
        <InteractiveLoadingProvider>
          <CssBaseline />
          <ToastContainer position="top-right" theme="colored" />
          <MainRoutes />
        </InteractiveLoadingProvider>
      </MsalProvider>
    </ReduxProvider>
  );
};

export default App;
