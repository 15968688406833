import { useEffect, useMemo } from "react";

export default function useResizeObserver(
  element: HTMLElement | null,
  callback: (el: HTMLElement) => void,
) {
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) =>
        entries.forEach((entry) => callback(entry.target as HTMLElement)),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(() => {
    if (!element) return () => {};
    resizeObserver.observe(element);
    return () => resizeObserver.unobserve(element);
  }, [element, resizeObserver]);
}
