import { Capacitor } from "@capacitor/core";
import { CloseOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { setFromUrl } from "src/services/store/mobileNavigationSlice";

interface IBackButtonProps {
  paddingTop?: string | number;
}

export default function BackButton({
  paddingTop = "0.5rem",
}: IBackButtonProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fromUrl = useAppSelector((state) => state.mobileNavigation.fromUrl);

  if (!Capacitor.isNativePlatform() || !fromUrl) return null;

  const handleClick = () => {
    dispatch(setFromUrl(null));
    navigate(fromUrl);
  };

  const actualPaddingTop =
    typeof paddingTop === "number" ? `${paddingTop}px` : paddingTop;

  return (
    <IconButton
      onClick={handleClick}
      sx={{
        position: "fixed",
        top: `calc(env(safe-area-inset-top) + ${actualPaddingTop})`,
        left: "calc(env(safe-area-inset-left) + 0.5rem)",
      }}
    >
      <CloseOutlined />
    </IconButton>
  );
}
