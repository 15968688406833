import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

export interface IState {
  fromUrl: string | null;
}

const initialState: IState = {
  fromUrl: null,
};

const mobileNavigationSlice = createSlice({
  name: "mobileNavigation",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setFromUrl: (state, action: PayloadAction<IState["fromUrl"]>) => {
      state.fromUrl = action.payload;
    },
  },
});

export const { setFromUrl } = mobileNavigationSlice.actions;
export default mobileNavigationSlice.reducer;
