import * as LiveUpdates from "@capacitor/live-updates";
import { App } from "@capacitor/app";

function getLiveUpdateChannel() {
  if (process.env.VITE_APP_ENVIRONMENT === "production") return "Production";
  if (process.env.VITE_APP_ENVIRONMENT === "dev") return "Development";
  return "";
}

async function capacitorSync() {
  const result = await LiveUpdates.sync();
  localStorage.shouldReloadApp = result.activeApplicationPathChanged;
}

export async function capacitorLiveUpdate() {
  await LiveUpdates.setConfig({
    channel: getLiveUpdateChannel(),
    enabled: process.env.VITE_APP_ENVIRONMENT !== "local",
  });

  // Register event to fire each time user resumes the app
  App.addListener("resume", async () => {
    if (localStorage.shouldReloadApp === "true") {
      await LiveUpdates.reload();
    } else {
      await capacitorSync();
    }
  });

  // First sync on app load
  await capacitorSync();
}
